import React from "react";
import UseData from "../../Hooks/UseData";
import Footer from "../../Share/Footer";
import "../../Share/Style.css";
import SliderCommon from "../../Share/SliderCommon";
import AboutCard from "./AboutCard";
import HomeCard from "../../Share/HomeCard";
import PageTitle from "../../Share/PageTitle";

const AboutTwo = () => {
  const { local, experienceArray } = UseData();

  return (
    <section>
      <PageTitle title="Chi sono"></PageTitle>
      {/* End pagetitle */}

      <div className=" lg:rounded-2xl bg-white dark:bg-[#111111]">
        <div data-aos="fade">
          <div className=" pt-12 md:py-12 px-2 sm:px-5 md:px-10 lg:px-14 ">
            {/* About page title */}
            <h2 className="after-effect after:left-52">Chi sono</h2>
            <div className="lg:hidden">
              {/* Sidebar personal information for mobile devices */}
              <HomeCard />
            </div>
            <div className="lg:grid grid-cols-12 md:gap-10 pt-4 md:pt-[30px] items-center">
              <div className="col-span-12 space-y-2.5">
                <div className="lg:mr-16">
                  <p className="text-gray-lite  dark:text-color-910 leading-7">
                    Svolgo da oltre 20 anni la mia attività professionale principalmente a Roma e provincia, ma ho operato in molte altre provincie d’Italia ed anche all’estero.
                  </p>
                  <p className="text-gray-lite leading-7 mt-2.5 dark:text-color-910">
                    Tali incarichi, unitamente a numerosi corsi di aggiornamento, hanno contribuito ad arricchire sempre più le mie competenze professionali.
                  </p>
                  <p className="text-gray-lite leading-7 mt-2.5 dark:text-color-910">
                    Svolgo attività professionale nel settore delle valutazioni immobiliari e nelle consulenze sia giudiziali che stragiudiziali.
                    Sono inoltre in possesso della certificazione ISO 17024 in conformità alla norma UNI 11558 e della qualifica REV (Recognised European Valuer) del TEGOVA.
                  </p>
                  <p className="text-gray-lite leading-7 mt-2.5 dark:text-color-910">
                    Lo studio elabora expertise mobiliari ed immobiliari tramite metodologie valutative che rispecchiano le best practices nazionali e internazionali.
                  </p>
                  <p className="text-gray-lite leading-7 mt-2.5 dark:text-color-910">
                    Specializzato nell’attività di Due Diligence tecniche, amministrative, legali e ambientali a supporto del Cliente nei processi di acquisizione, dismissione, gestione o valorizzazione.
                  </p>
                  <p className="text-gray-lite leading-7 mt-2.5 dark:text-color-910">
                    Certificazione Internazionale di Inglese Ascentis Level 2 (C1) International Certificate in ESOL - Advanced.
                  </p>
                </div>
                <div></div>
              </div>
            </div>
          </div>
          {/* End about descriptions */}

          <div className="px-2 sm:px-5 md:px-10 lg:px-14 ">
            {/* Slick Slider call here  */}
            <SliderCommon />
          </div>

          <div className="pb-12 px-2 sm:px-5 md:px-10 lg:px-14 ">
            <h2 className="after-effect-two after:left-72 mt-12 lg:mt-0 pb-5">
              Servizi professionali
            </h2>
            <div className="grid gap-8 grid-cols-1 md:grid-cols-2 xl:grid-cols-2 ">
              {/* Experience information  */}

              {experienceArray.map((item) => (
                <AboutCard key={Math.random()} item={item} local={local} />
              ))}
            </div>
          </div>
          {/* service provide end */}

          {/* Common Footer call here */}
          <Footer bg={"#FFFF"} />
        </div>
      </div>
    </section>
  );
};

export default AboutTwo;
